import type {
	IVehicleAssignTagParams,
	IVehicleBlockParams,
	IVehicleChangeAgreedRepurchaseDateParams,
	IVehicleChangeBrandParkingParams,
	IVehicleChangeCarrierParams,
	IVehicleChangeDeFleetDateParams,
	IVehicleChangeEndAmortizationDateParams,
	IVehicleChangeFleetTypologyParams,
	IVehicleChangeLastRevisionKmParams,
	IVehicleChangeNextITVDateParams,
	IVehicleChangeRegistrationDateParams,
	IVehicleChangeRentEndDateParams,
	IVehicleChangeRentStartDateParams,
	IVehicleChangeSaleAmountParams,
	IVehicleChangeStateParams,
	IVehicleChangeUnregisterTrafficDateParams,
	IVehicleChangeVehicleGroupParams,
	IVehicleCloseParams,
	IVehicleFleetSearchParams,
	IVehicleGetByPlateNumberParams,
	IVehicleGetReadyByCurrentBranchParams,
	IVehicleLockFiturParams,
	IVehicleLockParams,
	IVehicleOpenParams,
	IVehiclePreparationParams,
	IVehicleReceptionParams,
	IVehicleReturnParams,
	IVehicleRevisionParams,
	IVehicleSearchParams,
	IVehicleUnlockFiturParams,
	IVehicleUnlockParams,
	IVehicleUpdateKeysLocationParams,
	IVehicleUpdateObeParams,
} from '../../models/serviceParams/VehicleParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';
import { VehicleEndPoints } from '../endPoints/VehicleEndPoints';

export const vehicleServiceSearch = (model: IVehicleSearchParams): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.get<IVehicle[], IVehicleSearchParams>(VehicleEndPoints.SEARCH, model);
};

/**
 * Get vehicle by plate number
 *  {string} plateNumber Plate number
 * @returns {Promise} `ResponsePropType`
 */

export const vehicleServiceGetByPlateNumber = (
	model: IVehicleGetByPlateNumberParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.get<IVehicle, IVehicleGetByPlateNumberParams>(VehicleEndPoints.GET_BY_PLATE, model);
};

/**
 * Get available by branch and vehicle group order by ready
 *  {string} branchCode bookingNumber
 * @returns {Promise} `ResponsePropType`
 */

export const vehicleServiceGetReadyByCurrentBranch = (
	model: IVehicleGetReadyByCurrentBranchParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.get<IVehicle[], IVehicleGetReadyByCurrentBranchParams>(
		VehicleEndPoints.GET_READY_BY_CURRENT_BRANCH,
		model,
	);
};

/**
 * Update vehicle OBE, only in Portugal
 *  {string} plateNumber Plate number
 *  {string} obe OBE code
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceUpdateOBE = (model: IVehicleUpdateObeParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleUpdateObeParams>(VehicleEndPoints.UPDATE_OBE, model);
};

/**
 * Update vehicle nfc tag
 *  {string} plateNumber Plate number
 *  {number} add or modify nfc tag
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceAssignTag = (model: IVehicleAssignTagParams): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleAssignTagParams>(VehicleEndPoints.ASSIGN_TAG, model);
};

/**
 * Vehicle reception
 *  {Array} `Array<VehiclePropType>` Vehicles to reception
 *  {Array} files Images to vehicles reception
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceReception = (model: IVehicleReceptionParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleReceptionParams>(VehicleEndPoints.RECEPTION, model);
};

/**
 * Vehicles return
 *  {Array} `Array<VehiclePropType>`
 *  {HttpPostFile} image Image relate in this return
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceReturn = (model: IVehicleReturnParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleReturnParams>(VehicleEndPoints.RETURN, model);
};

/**
 * Change vehicle state
 *  {string} state Vehicle new state
 *  {string} message This message is to insert in expedient vehicle
 *  {string} supplierCode Supplier code, only if state is taller externo/interno
 * @returns {Promise} `VehiclePropType`
 */

export const vehicleServiceChangeState = (model: IVehicleChangeStateParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleChangeStateParams>(VehicleEndPoints.CHANGE_STATE, model);
};

export const vehicleServicePreparation = (model: IVehiclePreparationParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehiclePreparationParams>(VehicleEndPoints.PREPARATION, model);
};

export const vehicleServiceBlock = (model: IVehicleBlockParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleBlockParams>(VehicleEndPoints.BLOCK_VEHICLE, model);
};

export const vehicleServiceOpen = (model: IVehicleOpenParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleOpenParams>(VehicleEndPoints.OPEN, model);
};

export const vehicleServiceClose = (model: IVehicleCloseParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleCloseParams>(VehicleEndPoints.CLOSE, model);
};

export const vehicleServiceUnlock = (model: IVehicleUnlockParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleUnlockParams>(VehicleEndPoints.UNLOCK, model);
};

export const vehicleServiceLock = (model: IVehicleLockParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleLockParams>(VehicleEndPoints.LOCK, model);
};

export const vehicleServiceUnlockFitur = (model: IVehicleUnlockFiturParams) => {
	return Api.post<boolean, IVehicleUnlockFiturParams>(VehicleEndPoints.UNLOCK_FITUR, model);
};

export const vehicleServiceLockFitur = (model: IVehicleLockFiturParams) => {
	return Api.post<boolean, IVehicleLockFiturParams>(VehicleEndPoints.LOCK_FITUR, model);
};

export const vehicleServiceSearchFleet = (model: IVehicleFleetSearchParams) => {
	return Api.get<IVehicle[], IVehicleFleetSearchParams>(VehicleEndPoints.SEARCH_FLEET, model);
};

export const vehicleServiceChangeVehicleGroup = (model: IVehicleChangeVehicleGroupParams) => {
	return Api.post<IVehicle[], IVehicleChangeVehicleGroupParams>(VehicleEndPoints.CHANGE_VEHICLE_GROUP, model);
};

export const vehicleServiceChangeDeFleetDate = (
	model: IVehicleChangeDeFleetDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeDeFleetDateParams>(VehicleEndPoints.CHANGE_DEFLEET_DATE, model);
};

export const vehicleServiceChangeAgreedRepurchaseDate = (
	model: IVehicleChangeAgreedRepurchaseDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeAgreedRepurchaseDateParams>(
		VehicleEndPoints.CHANGE_AGREED_REPURCHASE_DATE,
		model,
	);
};

export const vehicleServiceChangeEndAmortizationDate = (
	model: IVehicleChangeEndAmortizationDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeEndAmortizationDateParams>(
		VehicleEndPoints.CHANGE_END_AMORTIZATION_DATE,
		model,
	);
};

export const vehicleServiceChangeNextITVDate = (
	model: IVehicleChangeNextITVDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeNextITVDateParams>(VehicleEndPoints.CHANGE_NEXT_ITV_DATE, model);
};

export const vehicleServiceChangeRentStartDate = (
	model: IVehicleChangeRentStartDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRentStartDateParams>(VehicleEndPoints.CHANGE_RENT_START_DATE, model);
};

export const vehicleServiceChangeRentEndDate = (
	model: IVehicleChangeRentEndDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRentEndDateParams>(VehicleEndPoints.CHANGE_RENT_END_DATE, model);
};

export const vehicleServiceChangeUnregisterTrafficDate = (
	model: IVehicleChangeUnregisterTrafficDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeUnregisterTrafficDateParams>(
		VehicleEndPoints.CHANGE_UNREGISTER_TRAFFIC_DATE,
		model,
	);
};

export const vehicleServiceUpdateKeysLocation = (
	model: IVehicleUpdateKeysLocationParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleUpdateKeysLocationParams>(VehicleEndPoints.UPDATE_KEYS_LOCATION, model);
};

export const vehicleServiceRevision = (model: IVehicleRevisionParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleRevisionParams>(VehicleEndPoints.REVISION, model);
};

export const vehicleServiceChangeLastRevisionKm = (
	model: IVehicleChangeLastRevisionKmParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleChangeLastRevisionKmParams>(VehicleEndPoints.CHANGE_LAST_REVISION_KM, model);
};

export const vehicleServiceChangeFleetTypology = (
	model: IVehicleChangeFleetTypologyParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeFleetTypologyParams>(VehicleEndPoints.CHANGE_FLEET_TYPOLOGY, model);
};

export const vehicleServiceChangeCarrier = (
	model: IVehicleChangeCarrierParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeCarrierParams>(VehicleEndPoints.CHANGE_CARRIER, model);
};

export const vehicleServiceChangeRegistrationDate = (
	model: IVehicleChangeRegistrationDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRegistrationDateParams>(VehicleEndPoints.CHANGE_REGISTRATION_DATE, model);
};

export const vehicleServiceChangeBrandParking = (
	model: IVehicleChangeBrandParkingParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeBrandParkingParams>(VehicleEndPoints.CHANGE_BRAND_PARKING, model);
};

export const vehicleServiceChangeSaleAmount = (
	model: IVehicleChangeSaleAmountParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeSaleAmountParams>(VehicleEndPoints.CHANGE_SALE_PRICE, model);
};
